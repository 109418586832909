<template>
  <div>
    <div class="err_404">

      即将返回首页!
    </div>
  </div>

</template>
<script>
export default {
  name: 'Empty',
  data() {
    return {
      description: '即将返回首页',
      timer: null
    }
  },
  mounted() {
    var time = 5
    this.timer = setInterval(() => {
      time--
      this.description = this.description + '。'
      if (time <= 0) {
        clearInterval(this.timer)
        this.$router.push('/')
      }
    }, 1000)
  },
  methods: {

  }
}
</script>
<style lang="less" scoped>
.err_404 {
  font-size: 22px;
  font-weight: 600;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
